import revive_payload_client_PAYG3pBUzr from "/vercel/path0/node_modules/.pnpm/nuxt@3.10.0_sass@1.70.0_typescript@5.3.3_vite@5.0.12/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_lKGhROzs8D from "/vercel/path0/node_modules/.pnpm/nuxt@3.10.0_sass@1.70.0_typescript@5.3.3_vite@5.0.12/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_IPGWEi4rjg from "/vercel/path0/node_modules/.pnpm/nuxt@3.10.0_sass@1.70.0_typescript@5.3.3_vite@5.0.12/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_G9a6sHyqmq from "/vercel/path0/node_modules/.pnpm/nuxt@3.10.0_sass@1.70.0_typescript@5.3.3_vite@5.0.12/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_85iiBOGifq from "/vercel/path0/node_modules/.pnpm/nuxt@3.10.0_sass@1.70.0_typescript@5.3.3_vite@5.0.12/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_xnPXcKrNvI from "/vercel/path0/node_modules/.pnpm/nuxt@3.10.0_sass@1.70.0_typescript@5.3.3_vite@5.0.12/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_nM77iXLQHo from "/vercel/path0/node_modules/.pnpm/@formkit+auto-animate@0.8.1/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import colors_ZdRpkjJ400 from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.13.0_nuxt@3.10.0_sortablejs@1.15.2_vite@5.0.12_vue@3.4.15/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_3dFO4wL2SD from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.3.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import formkitPlugin_pZqjah0RUG from "/vercel/path0/.nuxt/formkitPlugin.mjs";
import components_client_Ltleryrdqm from "/vercel/path0/node_modules/.pnpm/@nuxtjs+hanko@0.5.0/node_modules/@nuxtjs/hanko/dist/runtime/plugins/components.client.mjs";
import chunk_reload_client_fKdjOaYW7b from "/vercel/path0/node_modules/.pnpm/nuxt@3.10.0_sass@1.70.0_typescript@5.3.3_vite@5.0.12/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import chartjs_client_N7GULTnRpX from "/vercel/path0/plugins/chartjs.client.ts";
import client_A25i47TKRZ from "/vercel/path0/plugins/client.ts";
import error_handler_kEP5FliEXj from "/vercel/path0/plugins/error-handler.ts";
import headlessUI_sdHjT5xrml from "/vercel/path0/plugins/headlessUI.ts";
import oruga_2qeBGjDWKO from "/vercel/path0/plugins/oruga.ts";
export default [
  revive_payload_client_PAYG3pBUzr,
  unhead_lKGhROzs8D,
  router_IPGWEi4rjg,
  payload_client_G9a6sHyqmq,
  check_outdated_build_client_85iiBOGifq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_xnPXcKrNvI,
  plugin_nM77iXLQHo,
  colors_ZdRpkjJ400,
  plugin_client_3dFO4wL2SD,
  formkitPlugin_pZqjah0RUG,
  components_client_Ltleryrdqm,
  chunk_reload_client_fKdjOaYW7b,
  chartjs_client_N7GULTnRpX,
  client_A25i47TKRZ,
  error_handler_kEP5FliEXj,
  headlessUI_sdHjT5xrml,
  oruga_2qeBGjDWKO
]